<template>
    <LayoutNew>
        <h1 class="text-center">Daily Video Updates Management</h1>
        <MDBCard>
            <MDBCardHeader class="d-flex justify-content-between align-items-center p-4">
                <router-link :to="{ name: 'AddCreator' }">
                    <MDBBtn tag="a" color="primary" size="sm">
                        <MDBIcon iconStyle="fas" icon="user-plus" class="me-2"></MDBIcon> Add Creator
                    </MDBBtn>
                </router-link>
                <div>
                    <Search :searchType="'notifManagement'" @searchResult="notifManagementList = $event"
                        placeholder="Enter to Search" size="sm" />
                </div>
            </MDBCardHeader>
            <!-- table -->
            <MDBCardBody class="overflow-auto">
                <MDBTable striped hover class="align-middle mb-0 bg-white">
                    <thead class="table-dark">
                        <tr>
                            <th v-for="(item, i) in tableHeaders" :key="i" scope="col" class="text-nowrap">
                                {{ item.header }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="isLoading">
                            <tr>
                                <td colspan="3" class="text-center">
                                    <MDBSpinner />
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr v-if="notifManagementList.data.length === 0">
                                <td colspan="3" class="text-center py-3">No notification found.</td>
                            </tr>
                            <tr v-for="(item, i) in notifManagementList.data" :key="i">
                                <td>{{ item.creatorName }}</td>
                                <td>{{ item.recentVideoTitle }}</td>
                                <td class="text-nowrap">
                                    <!-- edit button -->
                                    <router-link :to="{ name: 'UpdateCreator', params: { id: item.id } }">
                                        <MDBBtn tag="a" outline="primary" size="sm" floating>
                                            <MDBIcon iconStyle="fas" icon="pen" />
                                        </MDBBtn>
                                    </router-link>
                                    <!-- delete button -->
                                    <button class="trash-btn btn ms-2 btn-outline-primary btn-floating btn-sm"
                                        @click.stop="clickAction(item, 'delete')">
                                        <em class="fa fa-trash" />
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </MDBTable>
            </MDBCardBody>
            <MDBCardFooter class="py-0">
                <Paginate :pageType="'notifManagementList'" @paginationData="notifManagementList = $event">
                </Paginate>
            </MDBCardFooter>
            <!-- delete modal -->
            <MDBModal id="deleteModal" tabindex="-1" labelledby="deleteModalLabel" v-model="deleteModal">
                <MDBModalHeader>
                    <MDBModalTitle id="deleteModalLabel"> Delete Creator Notification </MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody>Are you sure you want to delete this setting?</MDBModalBody>
                <MDBModalFooter class="gap-2 py-3">
                    <MDBBtn size="sm" @click="deleteModal = false">Close</MDBBtn>
                    <MDBBtn color="danger" size="sm" @click="deleteNotification(modalItem)">Confirm</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </MDBCard>
    </LayoutNew>
</template>

<script setup>
import LayoutNew from "./LayoutNew.vue";
import { onBeforeMount, ref, computed } from "vue";
import axios from "axios";
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardFooter,
    MDBBtn,
    MDBTable,
    MDBIcon,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBSpinner
} from "mdb-vue-ui-kit";
import Search from "@/components/CSDSearch.vue";
import Paginate from "../../components/Pagination/CSDPagination.vue";
import { useToastStore } from "@/store/toastStore";
import { useTitle } from "@vueuse/core";

useTitle("Video Update Management | CreatorShield");

const toastStore = useToastStore();

const tableHeaders = computed(() => [
    { header: "Creator Name" },
    { header: "Recent Video Title" },
    { header: "Actions" }
]);

const notifManagementList = ref({
    data: [],
    pagination: {},
});

const modalItem = ref("");
const deleteModal = ref(false);
const loadingSpinner = ref(false);

const clickAction = (item, type) => {
    modalItem.value = item;
    if (type === "delete") {
        deleteModal.value = true;
    }
};

const deleteNotification = (data) => {
    const toastSuccessDelete = {
        ...toastStore.toastStatus.success,
        message: "Successfully deleted!",
    };
    loadingSpinner.value = true;
    axios
        .patch("api/creatorvideoupdates/" + data.id + "/status")
        .then((response) => {
            if (response.status == 200) {
                getNotificationList();
                toastStore.toastObject = {
                    ...toastStore.toastObject,
                    ...toastSuccessDelete,
                };
                loadingSpinner.value = false;
                deleteModal.value = false;
            }
        })
        .catch(() => {
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastStore.toastStatus.fail,
            };
            loadingSpinner.value = false;
        });
};

const isLoading = ref(false);

const getNotificationList = async () => {
    isLoading.value = true;
    await axios
        .get("api/creatorvideoupdates/list")
        .then((response) => {
            isLoading.value = false;
            notifManagementList.value = response.data;
        })
        .catch(() => {
            isLoading.value = false;
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastStore.toastStatus.fail,
            };
        });
};

onBeforeMount(() => {
    getNotificationList();
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
</style>